<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="pendientes"
          :search="search"
          :options.sync="options"
          :single-expand="true"
          show-expand
          item-key="id"
          @item-expanded="detalle"
          sort-by="type"
          class="elevation-1"
          :loading="loading"
          loading-text="Extrayendo a los pendientes desde la base de datos, un momento..."
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>HSM</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-autocomplete
                v-model="modelOrigen"
                :items="itemsOrigen" 
                style="margin-top: 1.5em;"
                color="white"
                hide-no-data
                hide-selected
                item-text="label"
                item-value="id"
                label="Origen de Envio"
                placeholder="Asistente"
                prepend-icon="mdi-robot"
                return-object
                @change="seleccionAsistente"
              ></v-autocomplete>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-text-field
                  v-model="search" class="mr-3"
                  append-icon="mdi-magnify"
                  label="Buscar pendientes"
                  single-line
                  hide-details
                ></v-text-field>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-select
                v-model="status"
                :items="arrStatus" 
                class="mr-2 mt-4" 
                item-text="name"
                item-value="id"
                label="Estatus"
                persistent-hint
                single-line
                @change="initialize"
              ></v-select>
            </v-toolbar>
          </template>
          <template v-slot:item.status_envio="{ item }">
              
              <v-chip v-if="item.status_envio"
                color="green" dark outlined
              >Enviado</v-chip>
              <v-chip v-else
                color="danger" dark outlined
              >No enviado</v-chip>
            </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="autorizar(item)"
              v-if="item.tipo==2"
            >
              {{item.status?'mdi-close-circle-outline' :'mdi-checkbox-marked-circle-outline'}}  
            </v-icon>
          </template>
          <template v-slot:no-data>
            <!-- <v-btn
              color="primary"
              @click="initialize"
            >
              Reiniciar
            </v-btn> -->
          </template>
          <template v-slot:expanded-item="{ headers}">
            <td :colspan="headers.length">


              <v-data-table
                 v-if="show1"
                :headers="headersEnvio"
                :items="envio"
                :search="searchEnvio"
                sort-by="type"
                class="elevation-1"
                :loading="loading"
                loading-text="Extrayendo los envios desde la base de datos, un momento..."
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>Envio </v-toolbar-title>
                  
             

                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchEnvio" class="mr-3"
                        append-icon="mdi-magnify"
                        label="Buscar dentro del envio"
                        single-line
                        hide-details
                      ></v-text-field>
                     <download-csv
                        role="button"
                        class   = "btn btn-default"
                        :data   = "envio"
                        name    = "filename.csv">
                        <v-icon>mdi-file-excel</v-icon>
                        Exportar a Excel
                      </download-csv>
                  </v-toolbar>
                </template>
                      
                <template v-slot:item.param="{ item }">
                    <ul v-for="(parametro, index) in item.parametros.msj" :key="index"><li>{{parametro}}</li></ul>
                  <!-- {{item.parametros.msj}} -->
                </template>
                <template v-slot:item.param="{ item }">
                    <ul v-for="(parametro, index) in item.parametros.msj" :key="index"><li>{{parametro}}</li></ul>
                  <!-- {{item.parametros.msj}} -->
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
  
</template>
<script>
import Vue from 'vue'
import JsonCSV from 'vue-json-csv'
import ApiService from '../../service/apis'
Vue.component('downloadCsv', JsonCSV)

export default {
  name:'Pendientes',
  data: () => ({
      dialog: false,
      dialogDelete: false,
      selected: [{}],
      modelOrigen :{id:1,label:'Whatsapp Mexico'} ,
      itemsOrigen :[
        {id:1,label:'Whatsapp Mexico'},
        {id:2,label:'Whatsapp Hemodialisis'} ,
        {id:3,label:'Whatsapp San Salvador'} ,
        {id:4,label:'Whatsapp PetCt'},
        {id:5,label:'Whatsapp Medicos'} ,
        {id:6,label:'Whatsapp Medicos Internacional'}],
      search:null,
      searchEnvio:null,
      headers: [
        { text: 'HSM', value: 'cat_hsm.nombre_hsm' },
        { text: 'Detalle',  value: 'descripcion' },
        { text: '# de envios', value: 'total_envios' },
        { text: 'Fecha', value: 'fecha_envio' },
        { text: 'Hora', value: 'hora_envio' },
        { text: 'Usuario', value: 'asesor.nombre' },
        { text: 'Estado', value: 'status_envio' },
        { text: 'Autorizaciones', value: 'actions', sortable: false },
        
      ],
      headersEnvio: [
        { text: 'Telefono', align: 'start', value: 'userid' },
        { text: 'Fecha', value: 'fecha_envio' },
        { text: 'Hora', value: 'hora_envio' },
        { text: 'Status', value: 'estatus' },
      ],
      headersEnvio2: [
        { text: 'Telefono', align: 'start', value: 'parametros.telefono' },
        { text: 'Archivo', value: 'parametros.urlMedia' },
        { text: 'Parametros', value: 'param', sortable: false},
      ],
      pendientes: [],
      rol:[{_id:0,name:'Admin'},{_id:1,name:'Coordinador'}, {_id:2,name:'HSM'}],
      editedIndex: -1,
      show1:false,
      options:{},
      loading:false,
      arrStatus: [
        { id: 0, name: "TODAS" },
        { id: 1, name: "PENDIENTES" },
        { id: 2, name: "ENVIADOS" },
      ],
      status:0, 
      hsms:0,
      envio:{},
      sesion:JSON.parse(sessionStorage.getItem('sesion')),
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Alta de nuevo HSM' : 'Modificar HSM'
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
    },
    created () {
      this.seleccionAsistente()
    },
    updated(){
      this.evento()
    },
    methods: {
      evento(){
        this.$emit('Hsm', this.selected)
      }, 
      // editItem (item) {
      //   this.editedIndex = this.pendientes.indexOf(item)
      //   this.editedItem = Object.assign({}, item)
      //   this.dialog = true
      //   this.id=item.id
      // },
      // deleteItem (item) {
      //   this.editedIndex = this.pendientes.indexOf(item)
      //   this.editedItem = Object.assign({}, item)
      //   this.dialogDelete = true
      // },
      // deleteItemConfirm () {
      //   let borrar = {
      //     id: this.editedItem.user_id
      //   }
      //   ApiService.HsmBorrar(borrar).then(res =>{
      //     if(res){
      //       this.initialize()
      //       this.closeDelete()
      //     }
      //   })
      // },
      // close () {
      //   this.dialog = false
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem)
      //     this.editedIndex = -1
      //   })
      // },
      // closeDelete () {
      //   this.dialogDelete = false
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem)
      //     this.editedIndex = -1
      //   })
      // },
      save () {
        if (this.editedIndex > -1) {
          this.editedItem.uid=this.editedItem.email
          ApiService.editarpendientes(this.editedItem).then(res =>{
            if(res){
              this.initialize()
              this.close()
            }
          })
        } else {
          this.editedItem.uid=this.editedItem.email
          ApiService.nuevoHsm(this.editedItem).then(res =>{
            if(res){
              this.initialize()
              this.close()
            }
          })
        }
      },
      seleccionAsistente(){

        // if(this.modelOrigen.id == 1 || this.modelOrigen.id == 5){
        //   this.headersEnvio = [   
        //       { text: 'Telefono', align: 'start', value: 'userid' },
        //       { text: 'Fecha', value: 'fecha_envio' },
        //       { text: 'Hora', value: 'hora_envio' },
        //       { text: 'Status', value: 'estatus' },
        //   ];
        // }
        // else{
        //   this.headersEnvio = [
        //     { text: 'Telefono', align: 'start', value: 'parametros.telefono'},
        //     { text: 'Archivo', value: 'parametros.urlMedia' },
        //     { text: 'Parametros', value: 'param', sortable: false },
        //   ];
        // }

        this.loading=true 
        ApiService.getEnvios(this.status,this.modelOrigen.id).then(pendientes =>{
          this.hsms=pendientes.status 
          this.pendientes = pendientes.data
        }).finally(()=>{
          this.loading=false
        })
      },
      // detalle({ item }) {
      //   ApiService.getEnvio(item.id,this.modelOrigen.id).then((datos) => {
      //     this.envio = datos.data;
      //     this.show1=true
      //   });
      // },
       detalle({ item }) {   
          ApiService.getEnvioWhatsapp(item.id, this.modelOrigen.id).then((response) => {
          this.envio = response.data.map(item => {
            /* eslint-disable-next-line no-unused-vars */
            let { nombre_hsm, ...resto } = item;
            return resto;
          });
          this.show1 = true;
        });      
      },
      autorizar(item){
        let valida={
          capturista_id:this.sesion.id,
          envioId:item.id,
          status:!item.status,
          origen: this.modelOrigen.id
        }
        ApiService.validarEnvio(valida).then(() => {
          this.seleccionAsistente()
        });
      }
    },
}
</script>
<style>
.v-application .headline {
  letter-spacing: unset;
}
</style>